@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  @font-face {
    font-family: "Lato";
    font-weight: 200;
    src: url("./assets/fonts/Lato-Thin.ttf");
  }
  @font-face {
    font-family: "Lato";
    font-weight: 300;
    src: url("./assets/fonts/Lato-Light.ttf");
  }

  @font-face {
    font-family: "Lato";
    font-weight: 400;
    src: url("./assets/fonts/Lato-Regular.ttf");
  }

  @font-face {
    font-family: "Lato";
    font-weight: 500;
    src: url("./assets/fonts/Lato-Medium.ttf");
  }

  @font-face {
    font-family: "Lato";
    font-weight: 600;
    src: url("./assets/fonts/Lato-Semibold.ttf");
  }
  
  @font-face {
    font-family: "Lato";
    font-weight: 700;
    src: url("./assets/fonts/Lato-Bold.ttf");
  }
  @font-face {
    font-family: "Lato";
    font-weight: 900;
    src: url("./assets/fonts/Lato-Black.ttf");
  }
}

@layer base {
  .adnotation ul,
  .adnotation ol,
  .adnotation p {
    list-style: revert;
    margin: revert;
    padding: revert;
  }
  .adnotation table {
    border-width: 1px;
  }
  .adnotation table > tbody, .adnotation table > tbody > tr, .adnotation table > tbody > tr > td  {
    border-width: inherit;
    border-color: inherit;
  }
}

.popupScenario:has(.adnotation){
  @apply text-left;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: linear-gradient(180deg, #FFFFFF 21.92%, #F5F6F8 67.98%);
  background-attachment: fixed;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.checkBox-input:checked~.checkBox-content {
  @apply opacity-100;
}
.carousel-indicators [data-bs-target] {
  background-color: #005570;
  width: 10px;
}

.carousel-indicators [data-bs-target].active {
  width: 20px;
}

.hide-overflow {
  @apply overflow-y-hidden;
}

.whitePath > path {
  @apply stroke-white;
}
.greyPath > path {
  @apply stroke-greyBlue;
}
.whiteVector g {
  @apply fill-white;
}
.blueDisc g g path {
  @apply fill-darkBlue;
}
.greyEditIcon g g {
  @apply fill-darkGrey;
}
.whiteEditIcon g g {
  @apply fill-white;
}
.pathBlue path {
  @apply stroke-sherpaBlue;
}
.spinGold circle {
  @apply stroke-[#E0B16B40];
}
.spinGold path {
  @apply fill-gold;
}

.localVideoOff > div::after {
    @apply content-['Wideo-wyłączone'] text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  }

.input-error::after {
  content: attr(data-content);
  @apply text-error absolute bottom-[-40%] font-semibold text-sm pl-2 right-0;
}

